import Api from '../../api';
import ACTIONS from '../../actions';
import AirlineCancelValidator from './validator/airlineCancel';

let store = null;

export default class AirlineCancelService {
  constructor() {
    store = this.getStore('AirlineCancel');
    this.validator = {
      airlineCancel: new AirlineCancelValidator(),
    };
  }

  get = () => store.getState();

  cancelTicket = (provider, pnr) => Api.AirlineCancel.cancelTicket(provider, pnr)
    .then((res) => {
      store.dispatch({
        type: ACTIONS.AIRLINE_CANCEL.CANCEL_AIR_ITEM,
        payload: res,
      });
      return res;
    })
    .catch(() => store.dispatch({
      type: ACTIONS.AIRLINE_CANCEL.REQUEST_ERROR,
    }))

  resetStore = () => store.dispatch({
    type: ACTIONS.AIRLINE_CANCEL.RESET,
  });

  uploadLogoFile = async (file) => {
    try {
      const res = await Api.AirlineCancel.uploadLogoFile(file);
      store.dispatch({
        type: ACTIONS.AIRLINE_CANCEL.UPLOAD_LOGO_FILE,
        res,
      });
    } catch (error) {
      store.dispatch({
        type: ACTIONS.AIRLINE_CANCEL.UPLOAD_LOGO_FILE_ERROR,
      });
    }
  };

  uploadVoucherFile = async (file) => {
    try {
      const res = await Api.AirlineCancel.uploadVoucherFile(file);
      store.dispatch({
        type: ACTIONS.AIRLINE_CANCEL.UPLOAD_LOGO_FILE,
        res,
      });
    } catch (error) {
      store.dispatch({
        type: ACTIONS.AIRLINE_CANCEL.UPLOAD_LOGO_FILE_ERROR,
      });
    }
  };

  addAirlineCompany = data => Api.AirlineCancel.addAirlineCompany(data);

  subscribe = callback => store.subscribe(callback);

  airlineFieldValidation(...props) {
    return this.validator.airlineCancel.isValid(...props);
  }
}
