import debounce from 'es6-promise-debounce';

import { getDefaultMoment, getMoment } from '../../../bi/utils/formatDate';

import Api from '../../api';
import {
  mapAirVoucherToAirTickets,
  mapAwaitingAirVoucherToAddAirTickets,
} from './mapper';
import AirValidator from '../air/validator/air';
import AIRPROVIDERS from '../../constants/arline';
import { FIELDS, MODELS } from '../../constants/trips';

const TICKET = {
  Num: '',
  PNR: '',
  PNRProvider: '',
  PNRLocator: '',
  AppliedAviacode: '',
  Class: 'Economy',
  Travellers: null,
  Passport: null,
  PriceDetails: null,
  Routes: null,
  ProviderName: '',
};

const ROUTE = {
  RouteIndex: 0,
  Duration: getMoment().hours(0).minutes(0),
  Segments: null,
};
const AIRLINE = {
  Code: '',
  Name: '',
};
const AIRPORT = {
  Code: '',
  Name: '',
};
const SEGMENT = {
  AircraftName: '',
  MarketingAirline: null,
  OperatingAirline: null,
  ArrivalAirport: null,
  ArrivalCity: '',
  ArrivalCountry: '',
  ArrivalDate: getDefaultMoment(),
  ArrivalTime: getDefaultMoment(),
  ArrivalTerminal: '',
  Baggage: '',
  Class: '',
  DepartureAirport: null,
  DepartureCity: '',
  DepartureCountry: '',
  DepartureDate: getDefaultMoment(),
  DepartureTime: getDefaultMoment(),
  DepartureTerminal: '',
  FlightNumber: '',
  SubClassExtended: '',
};

const SEGMENT_VALIDATION = {
  AircraftName: '',
  MarketingAirline: null,
  OperatingAirline: null,
  ArrivalAirport: null,
  ArrivalCity: '',
  ArrivalCountry: '',
  ArrivalDate: '',
  ArrivalTime: '',
  ArrivalTerminal: '',
  Baggage: '',
  Class: '',
  DepartureAirport: null,
  DepartureCity: '',
  DepartureCountry: '',
  DepartureDate: '',
  DepartureTime: '',
  DepartureTerminal: '',
  FlightNumber: '',
  SubClassExtended: '',
};

const prepareAirNamesForSelect = () => {
  const {
    PORTBILET,
    TKP,
    SSEVEN,
    AMADEUS,
    AVIACENTR,
    ANGARA,
    AEROFLOT,
    TAIS,
    MIXVEL,
    AZIMUT,
    UZBEK,
    ETS,
  } = AIRPROVIDERS;

  return [
    { label: PORTBILET.VALUE, value: PORTBILET.TYPE, officeId: PORTBILET.ORDER_ID },
    { label: TKP.VALUE, value: TKP.TYPE, officeId: TKP.ORDER_ID },
    { label: SSEVEN.VALUE, value: SSEVEN.TYPE, officeId: SSEVEN.ORDER_ID },
    { label: AMADEUS.VALUE, value: AMADEUS.TYPE, officeId: AMADEUS.ORDER_ID },
    { label: AVIACENTR.VALUE, value: AVIACENTR.TYPE, officeId: AVIACENTR.ORDER_ID },
    { label: ANGARA.VALUE, value: ANGARA.TYPE, officeId: ANGARA.ORDER_ID },
    { label: AEROFLOT.VALUE, value: AEROFLOT.TYPE, officeId: AEROFLOT.ORDER_ID },
    { label: TAIS.VALUE, value: TAIS.TYPE, officeId: TAIS.ORDER_ID },
    { label: MIXVEL.VALUE, value: MIXVEL.TYPE, officeId: MIXVEL.ORDER_ID, salePoint: MIXVEL.SALE_ID },
    { label: AZIMUT.VALUE, value: AZIMUT.TYPE, officeId: AZIMUT.ORDER_ID },
    { label: UZBEK.VALUE, value: UZBEK.TYPE, officeId: UZBEK.ORDER_ID },
    { label: ETS.VALUE, value: ETS.TYPE, officeId: ETS.ORDER_ID },
  ];
};

const DEBOUNCE_TIME = 200;
const autocompleteDebounce = debounce(Api.Airport.autocomplete, DEBOUNCE_TIME);
const autocompleteAirlinesDebounce = debounce(Api.Airport.autocompleteAirlines, DEBOUNCE_TIME);

export default class AirService {
  constructor() {
    this.validator = {
      air: new AirValidator(),
    };
  }

  getAirProviderNamesForSelect = () => prepareAirNamesForSelect();

  getAirProviderOfficeIdForSelect = (providerName) => {
    const selectedProvider = prepareAirNamesForSelect().find(({ value }) => providerName === value);

    if (!selectedProvider) return [];

    const { officeId } = selectedProvider;

    if (!officeId) return [];

    return officeId;
  };

  autocomplete(value) {
    return autocompleteDebounce({ query: value });
  }

  autocompleteAirlines(value) {
    return autocompleteAirlinesDebounce({ query: value });
  }

  createNewTicket = (validation) => {
    const route = this.createNewRoute(validation);

    return ({
      ...TICKET,
      ProviderInfo: {
        OfficeId: null,
        SalePoint: null,
      },
      Routes: [route],
      PriceDetails: { ...MODELS.PriceDetails },
      VatDetails: MODELS.VatDetails.map(i => ({ ...i })),
      Passport: {
        EmployeeId: 0,
        PassportId: 0,
      },
    });
  };

  createNewRoute(validation) {
    const route = { ...ROUTE };
    route.Segments = [this.createNewSegment(validation)];

    return route;
  }

  getAirProviderSalePointForSelect = (providerName) => {
    const selectedProvider = prepareAirNamesForSelect().find(({ value }) => providerName === value);

    if (!selectedProvider) return [];

    const { salePoint } = selectedProvider;

    if (!salePoint) return [];

    return salePoint;
  };

  createNewSegment(validation = false) {
    const segment = validation ? { ...SEGMENT_VALIDATION } : { ...SEGMENT };
    segment.MarketingAirline = { ...AIRLINE };
    segment.OperatingAirline = { ...AIRLINE };
    segment.ArrivalAirport = { ...AIRPORT };
    segment.DepartureAirport = { ...AIRPORT };

    return segment;
  }

  createAirTickets = (item, airline) => mapAirVoucherToAirTickets(item, airline);

  createAwaitingAirTickets = (item, airline) => mapAirVoucherToAirTickets(item, airline, true);

  createAwaitingAddAirTickets = (item, airline) => mapAwaitingAirVoucherToAddAirTickets(item, airline);

  airTripFieldValidation(...props) {
    return this.validator.air.isValid(...props);
  }

  airTripFieldValidationAir(field, value) {
    return this.validator.air.isValidAir(field, value);
  }

  createNewValidator() {
    const result = this.createNewTicket(true);
    result.PriceDetails.Base = '';
    result.ProviderInfo.OfficeId = '';
    result.ProviderInfo.SalePoint = '';
    result.Routes[0].Duration = '';

    return result;
  }

  createNewValidatorByVoucher(vouchers) {
    return vouchers.map((voucher) => {
      const routes = voucher.Routes.map((route) => {
        const segments = route.Segments.map(() => ({
          ArrivalCity: '',
          ArrivalCountry: '',
          ArrivalDate: '',
          DepartureCity: '',
          DepartureCountry: '',
          DepartureDate: '',
          FlightNumber: '',
          MarketingAirline: {
            Code: '',
            Name: '',
          },
          OperatingAirline: {
            Code: '',
            Name: '',
          },
          ArrivalAirport: {
            Code: '',
            Name: '',
          },
          DepartureAirport: {
            Code: '',
            Name: '',
          },
        }));

        return {
          Duration: '',
          Segments: segments,
        };
      });

      return {
        Routes: routes,
        Num: '',
        PriceDetails: {
          Base: '',
        },
        Travellers: '',
        ProviderName: '',
        ProviderInfo: {
          OfficeId: '',
          SalePoint: '',
        },
      };
    });
  }

  createNewRouteValidator() {
    const result = this.createNewRoute(true);
    result.Duration = '';

    return result;
  }

  createNewSegmentValidator() {
    return this.createNewSegment(true);
  }

  validationAwaitingTicketing(ticket) {
    const num = this.airTripFieldValidation(null, null, 'Num', ticket.Num);
    const base = this.airTripFieldValidation(null, null, 'PriceDetails.Base', ticket.PriceDetails.Base);
    const providerName = this.airTripFieldValidation(null, null, 'ProviderName', ticket.ProviderName);

    let isValid = true;

    isValid = isValid ? (!num && !base && !providerName) : isValid;

    const validation = {
      Num: num,
      PriceDetails: {
        Base: base,
      },
      ProviderName: providerName,
    };

    return {
      isValid,
      validation,
    };
  }

  validationTicket(ticket, operationAirline) {
    const num = this.airTripFieldValidation(null, null, 'Num', ticket.Num, operationAirline);
    const base = this.airTripFieldValidation(null, null, 'PriceDetails.Base', ticket.PriceDetails.Base);
    const travellers = this.airTripFieldValidation(null, null, 'Travellers', ticket.Travellers);
    const pnr = this.airTripFieldValidation(null, null, 'PNR', ticket.PNR);
    const providerName = this.airTripFieldValidation(null, null, 'ProviderName', ticket.ProviderName);

    const officeId = ticket.ProviderInfo && ticket.ProviderName === AIRPROVIDERS.AMADEUS.TYPE ?
      this.airTripFieldValidation(null, null, FIELDS.PROVIDER_INFO.OFFICE_ID, ticket.ProviderInfo.OfficeId) :
      '';

    const salePoint = ticket.ProviderInfo && ticket.ProviderName === AIRPROVIDERS.MIXVEL.TYPE ?
      this.airTripFieldValidation(null, null, FIELDS.PROVIDER_INFO.SALE_POINT, ticket.ProviderInfo.SalePoint) :
      '';

    let isValid = true;
    const routesResult = [];
    const isValidDurations = [];

    for (let i = 0; i < ticket.Routes.length; i++) {
      const duration = this.airTripFieldValidation(ticket.Routes, null, FIELDS.DURATION, ticket.Routes[i].Duration);

      const segmentsResult = [];

      for (let j = 0; j < ticket.Routes[i].Segments.length; j++) {
        const arrivalCity = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'ArrivalCity',
          ticket.Routes[i].Segments[j].ArrivalCity
        );
        const arrivalCountry = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'ArrivalCountry',
          ticket.Routes[i].Segments[j].ArrivalCountry
        );
        const arrivalDate = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'ArrivalDate',
          ticket.Routes[i].Segments[j].ArrivalDate
        );
        const arrivalTime = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'ArrivalTime',
          ticket.Routes[i].Segments[j].ArrivalTime
        );
        const departureCity = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'DepartureCity',
          ticket.Routes[i].Segments[j].DepartureCity
        );
        const departureCountry = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'DepartureCountry',
          ticket.Routes[i].Segments[j].DepartureCountry
        );
        const departureDate = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'DepartureDate',
          ticket.Routes[i].Segments[j].DepartureDate
        );
        const departureTime = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'DepartureTime',
          ticket.Routes[i].Segments[j].DepartureTime
        );
        const flightNumber = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'FlightNumber',
          ticket.Routes[i].Segments[j].FlightNumber
        );
        const marketingAirlineCode = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'MarketingAirline.Code',
          ticket.Routes[i].Segments[j].MarketingAirline.Code
        );
        const marketingAirlineName = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'MarketingAirline.Name',
          ticket.Routes[i].Segments[j].MarketingAirline.Name
        );
        const operatingAirlineCode = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'OperatingAirline.Code',
          ticket.Routes[i].Segments[j].OperatingAirline.Code
        );
        const operatingAirlineName = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'OperatingAirline.Name',
          ticket.Routes[i].Segments[j].OperatingAirline.Name
        );
        const arrivalCode = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'ArrivalAirport.Code',
          ticket.Routes[i].Segments[j].ArrivalAirport.Code
        );
        const arrivalName = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'ArrivalAirport.Name',
          ticket.Routes[i].Segments[j].ArrivalAirport.Name
        );
        const departureCode = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'DepartureAirport.Code',
          ticket.Routes[i].Segments[j].DepartureAirport.Code
        );
        const departureName = this.airTripFieldValidation(
          ticket.Routes,
          ticket.Routes[i].Segments,
          'DepartureAirport.Name',
          ticket.Routes[i].Segments[j].DepartureAirport.Name
        );

        isValid = (
          !arrivalCity &&
          !arrivalCountry &&
          !arrivalDate &&
          !arrivalTime &&
          !departureCity &&
          !departureCountry &&
          !departureDate &&
          !departureTime &&
          !flightNumber &&
          !marketingAirlineCode &&
          !operatingAirlineCode &&
          !arrivalName &&
          !departureCode &&
          !departureName
        );

        segmentsResult.push({
          ArrivalCity: arrivalCity,
          ArrivalCountry: arrivalCountry,
          ArrivalDate: arrivalDate,
          ArrivalTime: arrivalTime,
          DepartureCity: departureCity,
          DepartureCountry: departureCountry,
          DepartureDate: departureDate,
          DepartureTime: departureTime,
          FlightNumber: flightNumber,
          MarketingAirline: {
            Code: marketingAirlineCode,
            Name: marketingAirlineName,
          },
          OperatingAirline: {
            Code: operatingAirlineCode,
            Name: operatingAirlineName,
          },
          ArrivalAirport: {
            Code: arrivalCode,
            Name: arrivalName,
          },
          DepartureAirport: {
            Code: departureCode,
            Name: departureName,
          },
        });
      }

      isValidDurations.push(!duration);

      routesResult.push({
        Duration: duration,
        Segments: segmentsResult,
      });
    }

    const duration = isValidDurations.some(isValidDuration => !isValidDuration);
    isValid = isValid ? !duration : isValid;

    isValid = isValid ? (!num && !base && !travellers && !providerName && !officeId && !pnr && !salePoint) : isValid;

    const validation = {
      Routes: routesResult,
      Num: num,
      PNR: pnr,
      PriceDetails: {
        Base: base,
      },
      Travellers: travellers,
      ProviderName: providerName,
      ProviderInfo: {
        OfficeId: officeId,
        SalePoint: salePoint,
      },
    };

    return {
      isValid,
      validation,
    };
  }

  changeTicketNumber = async (versionId, ticketNumber) => {
    try {
      await Api.Trip.changeAirlaneTicket(versionId, { Number: ticketNumber });
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err.body.Error);
    }
  }

  getAirlaneTicketClosing = async (versionId) => {
    try {
      const res = await Api.Trip.getAirlaneTicketClosing(versionId);

      return res;
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  }
}
