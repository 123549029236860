import moment from 'moment';

import { ERRORSFORALL, ERRORSFORAIR } from '../../../../bi/constants/tripValidator';
import { VALID_OPERATION_AIRLINES } from '../../../../bi/constants/airline';

import { pickValue, pickProviderName, requiredTime, pickProviderOfficeId } from '../../../utils/trip';
import { airTicketNUmberValidations } from '../../../constants/regExp';

const requiredField = value => Boolean(value.length);

const requiredValue = value => value;

const requiredValueNumber = (value, operatingAirline) => {
  const isValidOperationAirlines = VALID_OPERATION_AIRLINES.some(({ IATA }) => IATA === operatingAirline);
  const isValid = airTicketNUmberValidations.base.test(value) || airTicketNUmberValidations.special.test(value);

  return isValidOperationAirlines || isValid;
};

const requiredPNR = value => !!value && value.length !== 0;

const requiredDate = (value) => {
  if (typeof value === 'string') {
    return moment(value).isValid();
  }

  return moment.isMoment(value) && value.isValid();
};

const requiredEmployee = employee => employee && employee.Id && employee.Id > 0;

const validationObj = {
  CompanyId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOCOMPANY,
  },
  ProjectId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOPROJECT,
  },
  DepartmentId: {
    fn: pickValue,
    msg: ERRORSFORALL.NODEPARTMENT,
  },
  Description: {
    fn: requiredValue,
    msg: ERRORSFORALL.NODESCRIPTION,
  },
  Num: {
    fn: requiredValueNumber,
    msg: ERRORSFORAIR.NONUM,
  },
  PNR: {
    fn: requiredPNR,
    msg: ERRORSFORAIR.NOPNR,
  },
  ProviderName: {
    fn: pickProviderName,
    msg: ERRORSFORAIR.NOPROVIDER,
  },
  ProviderInfo: {
    OfficeId: {
      fn: pickProviderOfficeId,
      msg: ERRORSFORAIR.NO_OFFICE_ID,
    },
    SalePoint: {
      fn: pickProviderOfficeId,
      msg: ERRORSFORAIR.NO_SALE_POINT,
    },
  },
  Routes: {
    Duration: {
      fn: requiredDate,
      msg: ERRORSFORAIR.NOROUTESDURATION,
    },
    Segments: {
      MarketingAirline: {
        Code: {
          fn: requiredValue,
          msg: ERRORSFORAIR.NOSEGMENTSCODE,
        },
        Name: {
          fn: requiredField,
          msg: ERRORSFORAIR.NOSEGMENTSNAME,
        },
      },
      OperatingAirline: {
        Code: {
          fn: requiredValue,
          msg: ERRORSFORAIR.NOSEGMENTSCODE,
        },
        Name: {
          fn: requiredField,
          msg: ERRORSFORAIR.NOSEGMENTSNAME,
        },
      },
      ArrivalAirport: {
        Code: {
          fn: requiredValue,
          msg: ERRORSFORAIR.NOARRIVALAIRPORT,
        },
        Name: {
          fn: requiredField,
          msg: ERRORSFORAIR.NOARRIVALNAME,
        },
      },
      ArrivalCity: {
        fn: requiredField,
        msg: ERRORSFORAIR.NOARRIVALCITY,
      },
      ArrivalCountry: {
        fn: requiredField,
        msg: ERRORSFORAIR.NOCOUNTRY,
      },
      ArrivalDate: {
        fn: requiredDate,
        msg: ERRORSFORAIR.NOARRIVALDATE,
      },
      ArrivalTime: {
        fn: requiredTime,
        msg: ERRORSFORAIR.NOARRIVALTIME,
      },
      DepartureAirport: {
        Code: {
          fn: requiredValue,
          msg: ERRORSFORAIR.NODEPARTUREARPORT,
        },
        Name: {
          fn: requiredField,
          msg: ERRORSFORAIR.NODEPARTURENAME,
        },
      },
      DepartureCity: {
        fn: requiredField,
        msg: ERRORSFORAIR.NODEPARTURECITY,
      },
      DepartureCountry: {
        fn: requiredField,
        msg: ERRORSFORAIR.NOCOUNTRY,
      },
      DepartureDate: {
        fn: requiredDate,
        msg: ERRORSFORAIR.NODEPARTUREDATE,
      },
      DepartureTime: {
        fn: requiredTime,
        msg: ERRORSFORAIR.NODEPARTURETIME,
      },
      FlightNumber: {
        fn: requiredValue,
        msg: ERRORSFORAIR.NOFLIGHTNUMBER,
      },
    },
  },
  PriceDetails: {
    Base: {
      fn: requiredValue,
      msg: ERRORSFORAIR.NOPRICEDETAILS,
    },
  },
  Travellers: {
    fn: requiredEmployee,
    msg: ERRORSFORALL.NOEMPLOYEE,
  },
};

class AirValidator {
  isValid(routeInd, segmentInd, field, value, operatingAirline) {
    const part = field.split('.');
    let result = '';

    let vObj = validationObj;

    if (routeInd !== null && segmentInd !== null) {
      vObj = vObj.Routes.Segments;
    } else if (routeInd !== null) {
      vObj = vObj.Routes;
    }

    if (part.length === 1) {
      const validator = vObj[field];
      const validationFn = validator.fn;

      if (!validationFn(value, operatingAirline)) {
        result = validator.msg;
      }
    } else {
      const validator = vObj[part[0]][part[1]];
      const validationFn = validator.fn;

      if (!validationFn(value, operatingAirline)) {
        result = validator.msg;
      }
    }

    return result;
  }

  isValidAir(field, value, operatingAirline) {
    const validator = validationObj[field];
    const validationFn = validator.fn;
    let result = '';

    if (!validationFn(value, operatingAirline)) {
      result = validator.msg;
    }

    return result;
  }
}

export default AirValidator;
