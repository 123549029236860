import ACTIONS from '../../actions';

let store = null;

class AppService {
  constructor(InitStore) {
    store = InitStore || this.getStore('App');
  }

  get() {
    return store.getState();
  }

  subscribe(callback) {
    return store.subscribe(callback);
  }

  updateMenuItem(menuItem) {
    store.dispatch({
      type: ACTIONS.APP.SELECTEDMENUITEM,
      menuItem,
    });
  }

  getCurrentMenuItem() {
    return store.getState().menu.find(item => item.selected);
  }

  updateRoute(currentRoute) {
    store.dispatch({
      type: ACTIONS.APP.UPDATEROUTE,
      route: currentRoute,
    });
  }
}

export default AppService;
