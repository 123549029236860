import { enCharsWithNumbers, onlyNumbers, ruCharsWithNumbers } from '../../../constants/regExp';

const requireValue = value => !!value;
const checkINN = value => value.length === 10;
const checkKPP = value => value.length === 9;

const validationObj = {
  NameLatin: {
    fn: value => requireValue(value) && enCharsWithNumbers.test(value),
    msg: 'Введите название авиакомпании на латинице',
  },
  NameCyrillic: {
    fn: value => requireValue(value) && ruCharsWithNumbers.test(value),
    msg: 'Введите название авиакомпании на кириллице',
  },
  CodeCompany: {
    fn: value => requireValue(value) && enCharsWithNumbers.test(value),
    msg: 'Введите корректный код авиакомпании',
  },
  AirlineINN: {
    fn: value => onlyNumbers.test(value) && checkINN(value),
    msg: 'Введите корректный инн',
  },
  AirlineKPP: {
    fn: value => onlyNumbers.test(value) && checkKPP(value),
    msg: 'Введите корректный кпп',
  },
};

class AirlineCancelValidator {
  isValid(field, value) {
    let validator = '';
    let validationFn = '';

    validator = validationObj[field];
    validationFn = validator.fn;

    if (!validationFn(value)) {
      return validator.msg;
    }

    return '';
  }
}

export default AirlineCancelValidator;
