import AeroexpressValidator from './validator/aeroexpress';

class AeroexpressService {
  constructor() {
    this.validator = {
      aeroexpress: new AeroexpressValidator(),
    };
  }

  aeroexpressTripFieldValidation(...props) {
    return this.validator.aeroexpress.isValid(...props);
  }
}

export default AeroexpressService;
