import { MODELS } from '../../constants/trips';

const findAllSegments = (segmentsName, segments) => segments.filter(segment =>
  segmentsName.indexOf(`${segment.DepartureAirport.Code}-${segment.ArrivalAirport.Code}`) !== -1 ||
  segmentsName.indexOf(`${segment.DepartureAirport.CityCode}-${segment.ArrivalAirport.CityCode}`) !== -1
);

const splitSegmentsAirlineField = segments =>
  segments.map((segment) => {
    const newSegment = { ...segment };

    // Airline существовал в старой модели, где не было разделения на MarketingAirline и OperatingAirline
    if (!segment.MarketingAirline && !segment.OperatingAirline && segment.Airline) {
      newSegment.MarketingAirline = { ...segment.Airline };
      newSegment.OperatingAirline = { ...segment.Airline };
    }

    return newSegment;
  });

const createRoutes = (segments, routes) => {
  const routeResult = {};

  for (let i = 0, route = routes[0]; i < routes.length; i++, route = routes[i]) {
    let segmentsList = findAllSegments(segments, route.Segments);
    segmentsList = splitSegmentsAirlineField(segmentsList);

    if (segmentsList.length) {
      if (routeResult[i]) {
        routeResult[i].Segments = '';
      } else {
        routeResult[i] = {
          RouteIndex: i,
          Duration: route.Duration,
          Segments: segmentsList,
        };
      }
    }
  }

  return Object.keys(routeResult).map(key => routeResult[key]);
};

const createRoutesAwaitingTicketing = Routes => Routes.map((route) => {
  const segments = route.Segments.map((segment) => {
    const newSegment = { ...segment, HasValue: true };

      // Airline существовал в старой модели, где не было разделения на MarketingAirline и OperatingAirline
    if (!segment.MarketingAirline && !segment.OperatingAirline && segment.Airline) {
      newSegment.MarketingAirline = { ...segment.Airline };
      newSegment.OperatingAirline = { ...segment.Airline };
    }

    return newSegment;
  });

  return {
    Duration: route.Duration,
    Segments: segments,
    HasValue: true,
  };
});

const mapAirVoucherToAirTickets = (voucher, { Employees }, isAwaitingTicketing = false) => {
  if (typeof voucher === 'object' && !Array.isArray(voucher)) {
    return voucher.TicketsExtended.map((ticket) => {
      const segments = ticket.Segments.split(';').filter(item => item);

      const travellersInd = ticket.Travellers ? ticket.Travellers.split(':') : [0, 0];
      if (travellersInd[1] >= Employees.length) travellersInd[1] = Employees.length - 1;
      const travellers = Employees[travellersInd[1]];

      const routes = isAwaitingTicketing ? createRoutesAwaitingTicketing(voucher.Routes) : createRoutes(segments, voucher.Routes);
      const currentPassport = travellers.Documents.find(document => document.Selected);
      const passport = currentPassport || travellers.Documents[0] || {};
      const providerInfo = voucher.ProviderInfo ? { ...voucher.ProviderInfo } : null;

      const ticketRes = {
        Num: ticket.Num,
        PNR: ticket.PNR,
        PNRProvider: ticket.PNRProvider,
        PNRLocator: ticket.PNRLocator,
        Class: voucher.Class,
        AppliedAviacode: voucher.AppliedAviacode,
        Passport: {
          DueDate: passport.DueDate,
          PassportId: passport.Id,
          Number: passport.Number,
          Selected: passport.Selected,
          Type: passport.Type,
        },
        Travellers: {
          ...travellers,
        },
        PriceDetails: {
          ...ticket.PriceDetails,
          Taxes: ticket.PriceDetails && ticket.PriceDetails.Taxes ? ticket.PriceDetails.Taxes : 0,
        },
        VatDetails: MODELS.VatDetails.map(i => ({ ...i })),
        Routes: routes,
        ProviderName: voucher.ProviderName,
        ProviderInfo: providerInfo,
        FlightCertificate: voucher.FlightCertificate,
        ReferralLink: voucher.ReferralLink,
      };

      if (isAwaitingTicketing) {
        ticketRes.Fare = voucher.Fare;
      }

      return ticketRes;
    });
  }

  return voucher;
};

const mapAwaitingAirVoucherToAddAirTickets = (voucher, { Employees }) => {
  const currentPassport = Employees[0].Documents.find(document => document.Selected);
  const passport = currentPassport || Employees[0].Documents[0];


  return {
    Num: '',
    PNR: '',
    PNRProvider: '',
    PNRLocator: '',
    Class: '',
    Passport: {
      DueDate: passport.DueDate,
      PassportId: passport.Id,
      Number: passport.Number,
      Selected: passport.Selected,
      Type: passport.Type,
    },
    Travellers: {
      ...Employees[0],
    },
    PriceDetails: {
      ...MODELS.PriceDetails,
    },
    VatDetails: MODELS.VatDetails.map(i => ({ ...i })),
    Routes: voucher.Routes,
    ProviderName: '',
    ProviderInfo: {
      OfficeId: null,
    },
    FlightCertificate: voucher.FlightCertificate,
  };
};

export { mapAirVoucherToAirTickets, mapAwaitingAirVoucherToAddAirTickets };
