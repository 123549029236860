import { isMoment, isValidMomentObject } from '../../../utils/formatDate';
import { pickValue } from '../../../utils/trip';

import { ERRORSFORALL, ERRORSFORCUSTOM } from '../../../constants/tripValidator';

const requireValue = value => !!value;

const requiredDate = (value) => {
  if (typeof value === 'string') {
    return isValidMomentObject(value);
  }

  return isMoment(value) && isValidMomentObject(value);
};

const employees = employee => Boolean(employee.Id);

const validationObj = {
  CompanyId: {
    fn: requireValue,
    msg: ERRORSFORALL.NOCOMPANY,
  },
  DepartmentId: {
    fn: pickValue,
    msg: ERRORSFORALL.NODEPARTMENT,
  },
  Employees: {
    fn: employees,
    msg: ERRORSFORCUSTOM.NO_EMPLOYEE,
  },
  ProjectId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOPROJECT,
  },
  PassportId: {
    fn: requireValue,
    msg: ERRORSFORALL.NO_PASSPORT,
  },
  Price: {
    fn: requireValue,
    msg: ERRORSFORALL.NO_BASE,
  },
  TransactionId: {
    fn: requireValue,
    msg: ERRORSFORALL.NO_TRANSACTION_ID,
  },
  TicketNumber: {
    fn: requireValue,
    msg: ERRORSFORALL.NO_TICKET_NUMBER,
  },
  ReservationDate: {
    fn: requiredDate,
    msg: ERRORSFORALL.RESERVATION_DATE,
  },
};

class AeroexpressValidator {
  isValid(field, value) {
    let validator = '';
    let validationFn = '';

    validator = validationObj[field];
    validationFn = validator.fn;

    if (!validationFn(value)) {
      return validator.msg;
    }

    return '';
  }
}

export default AeroexpressValidator;
